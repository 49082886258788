import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"



//On importe les composants
import Header from '../components/header'
import Adresse from '../components/adresse'
import Footer from '../components/footer'
import BandeauContact from '../components/bandeau-contact'

//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe le CSS
import'../css/main.scss'
import '../css/footer.scss'
import '../css/adresse.scss'
import '../css/article.scss'
import '../css/bandeau-accueil.scss'
import '../css/bandeau-contact.scss'
import '../css/bandeau-page.scss'
import '../css/domaines.scss'
import '../css/formulaire.scss'
import '../css/header.scss'
import '../css/presentation.scss'
import '../css/resultats.scss'
import '../css/tableau.scss'

//On importe les SVG
// import Scroll from '../images/scroll.svg'


export default function ExcesVitesse() {


  const Sommaire = [
    { titre: "Quels sont les différents excès de vitesse et leurs sanctions ?", ancre: "sanction", lien: "#sanction" },
    { titre: "Comment contester un excès de vitesse ?", ancre: "contester", lien: "#contester" },
    { titre: "Le grand excès de vitesse – Excès de vitesse supérieur ou égal à 50 km/h", ancre: "grandexces", lien: "#grandexces" },
    { titre: "La récidive d’excès de vitesse supérieur ou égal à 50 km/h", ancre: "recidiveexces", lien: "#recidiveexces" },
    { titre: "La demande de comparution volontaire", ancre: "comparution", lien: "#comparution" }
  ]


  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Excès de vitesse : Protéger son permis de conduire</title>
        <meta name="description" content="Vous avez commis un grand excès de vitesse ? Contester une infraction est toujours possible. Les radars ne sont pas infaibles. Un mauvais usage ou un mauvais entretien peut engendrer des erreurs." />
        <html lang="fr" />
        <link rel="icon" href={Favicon} />


      </Helmet>

      <Header />

      <div className="BackGrey">
        <div className="BandeauMain">

          <div className="BandeauPageContainer">

            <h1>EXCÈS DE VITESSE</h1>
            <h2>Vous avez commis un grand excès de vitesse ?</h2>

          </div>

        </div>

      </div>


      <div className="AccrochePageMain">

        <div className="AccrochePageContainer">

          <div className="AccrochePageItem">

            <StaticImage src="../images/portrait-debrabant.jpg" alt="thierry debrabant avocat" placeholder="blurred" layout="fixed" width={200} height={200} />

          </div>

          <div className="AccrochePageItem">

            <h4 align="center">Maître Thierry DEBRABANT, Avocat</h4>

            <p align="justify">
              “Contester un excès de vitesse est toujours possible. Les radars ne sont pas infaibles. Une mauvaise utilisation ou un mauvais entretien peut engendrer des verbalisations à tort.”
            </p>

            <div className="AccrochePageItemLink">
              <Link to="#contact">Contacter</Link>
            </div>

          </div>


        </div>
      </div>


      <div className="SommairePage">
        <h2>SOMMAIRE</h2>
        <ul>
          {
            Sommaire.map((item, i) => {
              return (
                <li key={i}><a href={item.lien}>{item.titre}</a></li>
              )
            })
          }

        </ul>
      </div>

      <div className="TableauMain">

        {/* {
          Sanctions.map((item, i) => {
            return (
              <div className="TableauContainer" key={i}>

                <ul className="TableauTitre">
                  <li>{item.titrec1}</li>
                  <li>{item.titrec2}</li>
                  <li>{item.titrec3}</li>
                  <li>{item.titrec4}</li>
                </ul>

                <ul className="TableauItem">
                  <li>{item.l1c1}</li>
                  <li>{item.l1c2}</li>
                  <li>{item.l1c3}</li>
                  <li>1{item.l1c4}</li>
                </ul>

                <ul className="TableauItem">
                  <li>{item.l2c1}</li>
                  <li>{item.l2c2}</li>
                  <li>{item.l2c3}</li>
                  <li>{item.l2c4}</li>
                </ul>

                <p align="justify">{item.commentaire} {item.commentaire ? <Link to="#retention">En savoir plus...</Link> : ""}</p>

                <img src={Scroll} width="50rem" alt="scroll" />

              </div>
            )
          })

        } */}

        <h3 align="center" id={Sommaire[0].ancre}>{Sommaire[0].titre}</h3>
        <div className="Contenu">
          <p align="justify">Plutôt qu’une liste de sanctions que vous pourrez retrouver sans difficultés sur les sites internet du gouvernement, voilà ce qu’il faut retenir :</p>
          <p align="justify">Les excès de vitesses sont généralement punis d’une peine d’amende contraventionnelle de 68 à 135 €.</p>
          <p align="justify">Plus l’excès de vitesse sera important par rapport à la vitesse autorisée, plus le nombre de points retranché sur le permis de conduire sera important :</p>

          <ul>
            <li align="justify">1 seul point pour un excès de vitesse de moins de 20 km/h</li>
            <li align="justify">2 points pour un excès de vitesse de 20 à 29 km/h</li>
            <li align="justify">3 points pour un excès de vitesse de 30 à 39 km/h</li>
            <li align="justify">4 points pour un excès de vitesse de 40 km/h mais inférieur à 50 km/h</li>
            <li align="justify">6 points pour un excès de vitesse égale ou supérieur à 50 km/h</li>
          </ul>

          <p align="justify">Pour tous les excès de vitesse, la suspension du permis de conduire est possible jusque 36 mois, et oui même pour un excès de vitesse de moins de 20 km/h (et même pour un défaut de clignotant d’ailleurs, ce que la plupart d’entre nous ignorent).</p>
          <p align="justify">Pour tous les excès de vitesse inférieurs à 50 km/h, le Tribunal peut vous accorder un « permis blanc », c’est à dire aménager la suspension du permis de conduire en réservant son application en dehors des horaires de travail.</p>
          <p align="justify">Le Préfet va alors, dans ce délai de 72 heures, prendre une décision dite de suspension provisoire immédiate du permis de conduire, qui est une décision de nature administrative intervenant avant même tout jugement et qui suspend le permis de conduire pour une durée de 6 mois au maximum.</p>
          <p align="justify">D’expérience, la suspension préfectorale oscille généralement entre 2 et 4 mois pour un excès de vitesse compris entre 40 et 50 km/h, et entre 3 et 6 mois pour un excès de vitesse supérieur ou égal à 50 km/h.</p>
          <p align="justify">Vous ferez en parallèle l’objet de poursuites judiciaires pour <b>grand excès de vitesse</b>, soit devant la Juridiction de proximité, le Tribunal de Police ou le Tribunal Correctionnel (en cas de récidive).</p>


        </div>





        <h3 align="center" id={Sommaire[1].ancre}>{Sommaire[1].titre}</h3>
        <div className="Contenu">
          <p align="justify">Contester une contravention d’excès de vitesse est toujours possible, et même indispensable si vous pensez avoir été verbalisé à tort, ce qui arrive régulièrement, les appareils cinémomètres étant peu fiables et parfois mal utilisés et/ou mal entretenus par les forces de l’ordre.</p>
          <p align="justify">Il est tout aussi impératif de contester lorsque son permis de conduire présente un solde de points insuffisant, ce qui expose à une annulation de permis de conduire qui entraine souvent la perte de l’emploi pour les professionnels de la route où ceux pour qui la conduite d’un véhicule est indispensable pour accomplir leurs déplacements professionnels.</p>
        </div>

        <h3 align="center" id={Sommaire[2].ancre}>{Sommaire[2].titre}</h3>
        <div className="Contenu">
          <p align="justify">L’excès de vitesse supérieur ou égal à 50 km/h (souvent appelé grand excès de vitesse) est une contravention de 5ème classe qui entraîne le retrait de 6 points sur le permis de conduire.</p>
          <p align="justify">Les sanctions prévues à l’article R 413-14-1 du Code de la route sont les suivantes :</p>

          <ul>
            <li align="justify">Une peine d’amende d’un montant maximal de 1500 €.</li>
            <li align="justify">La suspension du permis de conduire pour 36 mois maximum sans aménagement possible ni sursis même pour les besoins de l’activité professionnelle</li>
            <li align="justify">L’interdiction de conduire les véhicules terrestres à moteur si vous n’êtes pas titulaire du permis de conduire</li>
            <li align="justify">L’obligation d’accomplir un stage de sensibilisation routière aux frais de la personne condamnée</li>
            <li align="justify">La confiscation du véhicule ayant servi à commettre l’infraction si l’auteur en est le propriétaire</li>
            <li align="justify">La contravention donne lieu à un retrait de 6 points sur le permis de conduire</li>
          </ul>

          <p align="justify">L’automobiliste peut donc faire l’objet en l’espèce d’une  « double peine » , car, en plus de la suspension administrative décidée par le Préfet, le Tribunal peut le condamner à une suspension du permis de conduire supplémentaire pouvant aller jusqu’à 3 ans.</p>
          <p align="justify">Les poursuites en justice contre l’automobiliste ayant commis un excès de vitesse supérieur ou égal à 50 km/h se font :</p>

          <ul>
            <li align="justify">Soit par la voie de l’ordonnance pénale contraventionnelle</li>
            <li align="justify">Soit par la voie de la convocation en justice devant le Tribunal de Police ou le Tribunal Correctionnel (en cas de récidive de grand excès de vitesse).</li>
          </ul>

          <p align="justify">Des solutions existent pour limiter fortement la durée de la suspension du permis de conduire avec notamment la demande de comparution volontaire (voir infra).</p>

          <p align="justify">N’hésitez pas à prendre contact avec le Cabinet au 03.28.35.68.20 ou via debrabant.avocat@outlook.fr si vous souhaitez une consultation approfondie.</p>

        </div>

        <h3 align="center" id={Sommaire[3].ancre}>{Sommaire[3].titre}</h3>
        <div className="Contenu">
          <p align="justify">Dans cette hypothèse, les sanctions encourues sont beaucoup plus lourdes, à savoir :</p>

          <ul>
            <li align="justify">Une peine d’emprisonnement de 3 mois et 3750 € d’amende</li>
            <li align="justify">La confiscation obligatoire (à laquelle il peut toutefois être dérogée) du véhicule ayant servi à commettre l’infraction</li>
            <li align="justify">Une peine de suspension du permis de conduire pour une durée de 36 mois</li>
            <li align="justify">L’interdiction de conduire tous les véhicules terrestres à moteur pour une durée maximale de 5 ans</li>
            <li align="justify">Le retrait de 6 points sur le permis de conduire</li>
          </ul>

          <p align="justify">L’état de récidive légale pour un grand excès de vitesse est retenu quand une condamnation pour des faits similaires a été prononcée à l’encontre de l’automobiliste dans un délai inférieur à 3 ans.</p>
          <p align="justify">Ce n’est donc plus une contravention de la 5ème classe, mais un délit, et l’automobiliste est alors convoqué devant le Tribunal Correctionnel.</p>

          <p align="justify">Dans tous les cas, et pour tous les excès de vitesse, il existe de nombreux arguments et stratégies parfaitement légales et reconnues par les tribunaux pour conserver son permis de conduire.</p>

          <p align="justify">N’hésitez pas à prendre contact avec le Cabinet au 03.28.35.68.20 ou via debrabant.avocat@outlook.fr si vous souhaitez une consultation approfondie. </p>

        </div>

        <h3 align="center" id={Sommaire[4].ancre}>{Sommaire[4].titre}</h3>
        <div className="Contenu">
          <p align="">La demande de comparution volontaire pour raccourcir la durée de la suspension provisoire et récupérer rapidement son permis de conduire après une suspension administrative.</p>
          <p align="justify">Lorsqu’un automobiliste est suspecté d’avoir commis une infraction au code de la route d’une certaine gravité (alcool ou stupéfiants au volant, excès de vitesse de plus de 40 km/h), le permis fait généralement l’objet d’une rétention immédiate afin de permettre au représentant de l’Etat dans le département (Préfet ou Sous-Préfet) de prendre un arrêté de suspension provisoire du permis de conduire.</p>

          <p align="justify">En d’autres termes, l’automobiliste qui fait l’objet de cette procédure perd immédiatement le droit de conduire des véhicules pour lesquels le permis de conduire est exigé pour la durée prévue à l’arrêté qui est généralement de 4 à 6 mois, mais qui peut être de 9 à 12 mois pour les cas les plus graves. </p>

          <p align="justify">Il existe des possibilités de recours administratifs (recours gracieux ou contentieux devant le Tribunal), mais la durée de traitement de ces derniers les rend inefficaces car la réponse de l’administration ou du tribunal intervient souvent trop tard une fois la suspension en grande partie effectuée. </p>

          <p align="justify">Le régime actuel de la suspension provisoire est donc scandaleux (oui, le mot n’est pas trop fort), car il n’existe quasiment aucun recours efficace et vous n’êtes convoqué devant le Tribunal qui doit statuer sur la suspension judiciaire (donc définitive) de votre permis de conduire une fois que cette dernière est intégralement effectuée ! </p>

          <p align="justify">En d’autres termes, non seulement l’automobiliste a été sanctionné par cette suspension administrative alors qu’il se trouve toujours présumé innocent, mais en plus il ne peut contester cette suspension une fois qu’elle est en tout ou partie déjà effectuée.</p>

          <p align="justify">Ainsi lorsque le Tribunal juge l’affaire, il ne peut en réalité plus effectivement revenir sur la durée de la suspension administrative puisque cette dernière est déjà terminée. </p>

          <p align="justify">La requête visant à solliciter une comparution volontaire anticipée vise donc à obtenir une date audience la plus rapide possible pour que le Tribunal puisse écourter la durée de la suspension provisoire, voire en dispenser totalement le prévenu. </p>

          <p align="justify">Bien évidemment, il ne suffit pas de comparaitre rapidement devant son juge pour obtenir automatiquement une dispense de suspension du permis de conduire. </p>

          <p align="justify">Le dossier devra être en effet soigneusement préparé et il faut connaître les spécificités de chaque juridiction pour mettre toutes les chances de son côté. </p>

          <p align="justify">En cas de rétention immédiate de votre permis de conduire pour excès de vitesse, alcool ou stupéfiants volant, ne perdez pas de temps et contacter moi pour voir s’il est possible de déposer une requête en comparution volontaire et ainsi raccourcir au maximum la durée de la suspension de votre permis de conduire. </p>
          <p align="justify">N’hésitez pas à prendre contact avec le Cabinet au 03.28.35.68.20 ou via debrabant.avocat@outlook.fr </p>


        </div>



      </div>


      <BandeauContact />

      <Adresse />

      <Footer />


    </HelmetProvider >
  )
}
