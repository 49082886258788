import * as React from "react"

//On importe le css



export default function BandeauContact() {



    return (

        <div className="BackBandeauContact">

            <div className="MainBandeauContact">

                <p>Des questions ?<br/>Besoin de conseils ?</p>
                <a href="#contact">Contacter le cabinet</a>

            </div>

        </div>


    )
}